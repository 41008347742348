// depends 
import React, {useState, useEffect, useRef} from 'react';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import emailjs from '@emailjs/browser';
import axios from 'axios';

// data
import {knowledgehome} from "../../assets/data/guides.js";

// css 
import '../../CSS/Knowledge/BBSForm.css';

const BBSForm = () => {
    const nav = useNavigate();
    const {register, handleSubmit, formState: {errors}} = useForm({
        defaultValues: {
            name: '',
            topic: '',
            topictitle: '',
            // images: [],
            url1: "",
            url2: "",
            url3: ""
        }
    });

    const [submitting, setSubmitting] = useState();
    var EJS = useRef({
        service: "",
        template: "template_7ebuz41",
        key: ""  
    })

    // get EJS info 
    useEffect(() => {
        if (EJS.current.service === "" && EJS.current.service === '') {
            axios.get("https://website-worker.audiocontractorsllc.workers.dev/VtCBSHBM1QsDjEjtCJQ7FG5Xswg446h94ZGNdJMB2xI=")
                .then((res) => {
                    EJS.current.service = res.data
                    console.log('controller updated with service ID', EJS)
                })
                .catch((err) => {
                    console.log("could not reach worker", err)
                })
        
            axios.get("https://website-worker.audiocontractorsllc.workers.dev/pALE14RC3CnIQbcRPPemWaNnzjhELfk6C9F4kT3LHQ=")
                .then((res) => {
                    EJS.current.key = res.data
                    console.log('controller updated with key', EJS)
                })
                .catch((err) => {
                    console.log("could not reach worker", err)
            })  
        }
    },[])

    // used the check if the errors object is empty in order to show or hide the container at the bottom of the screen. 
    const isEmpty = (obj) => {
        if (Object.keys(obj).length === 0) {
            return false
        } else {
            return true
        }
    };

    // used to check the existence of a specified error, to apply element.style properties to inputs.
    const hasError = (obj, target) => {
        if (Object.keys(obj).includes(target)) {
            return true
        } else {
            return false
        }
    };

    const emailSend = (data) => {
        setSubmitting(true)
        emailjs.send(EJS.current.service, EJS.current.template, data, EJS.current.key)
            .then((res) => {
                console.log(res.status)
                setTimeout(() => {
                    nav('/confirmation')
                },[2000])
            })
            .catch((err) => {
                console.log('there was an error on the emailing:', err)
            })
    }


    return (
        <div className='BBSForm'>
            <div className='BBSForm__loadingContainer' style={{display: submitting ? 'flex' : 'none'}}>
                <div class="three-quarter-spinner"></div>
            </div>
            <form id="form_bbsform" className="bbsform__form" onSubmit={handleSubmit(emailSend)} style={{display: submitting ? 'none' : 'flex'}}>
            {/* name */}
            <label id='label_name' className='bbsform__label' data-lpignore="true" >Your Name</label>
            <input
                id='name'
                name='name'
                className='bbsform__input'
                type='text'
                style={{border: hasError(errors,'name') ? "1.5px solid #F20000" : "1.5px solid #1E132A"}}
                {...register("name", { required: true, maxLength: 50 })} 
            />
            {/* single select input for topic area */}
                {/* if other, show inpuot field for topic area */}
            <label id='label_topic' className='bbsform__label' data-lpignore="true" >Topic</label>
            <select 
                id='topic'
                name='topic'
                className='bbsform__select'
                style={{border: hasError(errors,'topic') ? "1.5px solid #F20000" : "1.5px solid #1E132A"}}
                {...register("topic", {required: true})}
            >
             {knowledgehome.map((item) => {
                return (
                    <option value={item.name} key={`option_${knowledgehome.indexOf(item)}`}>{item.name}</option>
                ) 
             })}
            <option value="New Topic" key='option_newtopic'>Suggest New Topic</option>

            </select>
            {/* title of comment */}
            <label id='label_topictitle' className='bbsform__label' data-lpignore="true" >Title of Post</label>
            <input
                id='topictitle'
                name='topictitle'
                className='bbsform__input'
                type='text'
                style={{border: hasError(errors,'topictitle') ? "1.5px solid #F20000" : "1.5px solid #1E132A"}}
                {...register("topictitle", { required: true, maxLength: 50 })} 
            />
            {/* comments with open ended formatting  maybe look at a good text editor modal?*/}
            <label id="label_comment" className='bbsform__label' >Enter comment below</label>
            <textarea 
                id="bbsform_comment" 
                className='bbsform__textarea' 
                placeholder="Enter text here"
                
                {...register("comment", {required: true})} 
            />

            
            {/* reference URL (if applicable) */}
            <label id="label_url" className='bbsform__label' >Reference URL (if applicable)</label>
            <input
                id='url1'
                name='url1'
                className='bbsform__input'
                type='url'
                style={{border: hasError(errors,'url1') ? "1.5px solid #F20000" : "1.5px solid #1E132A"}}
                {...register("url1", { required: false, maxLength: 200 })} 
            />
            <input
                id='url2'
                name='url2'
                className='bbsform__input'
                type='url'
                style={{border: hasError(errors,'url2') ? "1.5px solid #F20000" : "1.5px solid #1E132A"}}
                {...register("url2", { required: false, maxLength: 200 })} 
            />
            <input
                id='url3'
                name='url3'
                className='bbsform__input'
                type='url'
                style={{border: hasError(errors,'url3') ? "1.5px solid #F20000" : "1.5px solid #1E132A"}}
                {...register("url3", { required: false, maxLength: 200 })} 
            />
            <div className='bbsform__errors' style={{display: isEmpty(errors) ? "block" : "none"}}>
                <p className='errors__dialog'>Please resolve the following errors before submitting:</p>
                <ul className='errors__list'>
                    {errors.name && <li className='bbsform__error'>&#8226; Please enter your name</li>} 
                    {errors.topic && <li className='bbsform__error'>&#8226; please select a subject. use 'other' if subject is not listed.</li>} 
                    {errors.topictitle && <li className='bbsform__error'>&#8226; please include a title</li>} 
                    {errors.comment && <li className='bbsform__error'>&#8226; please include a description </li>} 
                    {errors.url1 && <li className='bbsform__error'>&#8226; please enter valid URL</li>}      
                    {errors.url2 && <li className='bbsform__error'>&#8226; please enter valid URL</li>}      
                    {errors.url3 && <li className='bbsform__error'>&#8226; please enter valid URL</li>}      
                </ul>
            </div>
            <input type="submit" className='bbsform__submit' id='btn_bbssubmit' lable="submit" value="Submit"/>
            </form>
        </div>
    );
};

export default BBSForm;